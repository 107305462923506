import React from "react";
import { Button, Container, Row, Col, Input, Form, Alert, Spinner } from "reactstrap";
import { Redirect } from "react-router-dom";

function Register({ type, successMsg }) {
  // const urlParams = new URLSearchParams(window.location.search);
  // const utm_source = urlParams.get('utm_source');
  // const utm_medium = urlParams.get('utm_medium');
  // const utm_campaign = urlParams.get('utm_campaign');

  const searchParams = new URLSearchParams(window.location.search);
  const editionLink = searchParams.get('edition');

  function getCurrentDate(separator = "/") {
    let newDate = new Date();
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    return `${year}${separator}${month < 10 ? `0${month}` : `${month}`}${separator}${date}`;
  }

  function gettimeinampm() {
    let date = new Date();
    let hours = date.getHours();
    let minutes = date.getMinutes();
    const ampm = hours >= 12 ? "pm" : "am";
    hours %= 12;
    if (hours === 0) {
      hours = 12;
    }
    // hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? `0${minutes}` : minutes;
    // const strTime = hours + ':' + minutes + ' ' + ampm;
    return `${hours}:${minutes} ${ampm}`;
  }

  const [details, setDetails] = React.useState({
    datetime: `"${getCurrentDate("/")} ${gettimeinampm()}"`,
    type,
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    company: '',
    country: '',
    job: '',
    city: '',
    industry: '',
    marketing: 'No',
    consent: 'No',
    edition: editionLink === 'August2025' ? edition[1] : edition[0],
  });
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [redirect, setRedirect] = React.useState(false);

  const onChangeDetails = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
  };
  // const checkboxChange = (e) => {
  //   setDetails({ ...details, [e.target.name]: e.target.checked });
  // };
  const onSubmitForm = (e) => {
    e.preventDefault();
    setLoading(true);
    setError(false);
    setSuccess(false);

    const reg = /[a-zA-Z0-9]*@[gmail|yahoo|hotmail]+\.[com|co.in]+/;
    if (reg.test(details.email.toLowerCase())) {
      setLoading(false);
      setError("Please enter your business email only");
      return null;
    }

    fetch("https://api.khaleejtimesevents.com/cx-evolve/rg.php", {
      // fetch('https://ktuniexpo.com//cx-evolve/rg.php https://asia-east2-cmoconnext.cloudfunctions.net/api/cxevolve', {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(details),
    })
      .then((res) => {
        console.log(res);
        if (res.ok) return res;
        else throw new Error(res);
      })
      .then(() => {
        setLoading(false);
        setRedirect(true);
      })
      .catch((err) => {
        setLoading(false);
        setError("Something went wrong please try again");
      });
  };
  let inputs = content;
  if (type === "BROCHURE") {
    inputs = content.filter((c) => [1, 2, 3, 4, 5, 6, 7, 8].includes(c.id));
  }
  if (type === "REPORT") {
    inputs = content.filter((c) => [1, 2, 3, 4, 5, 6].includes(c.id));
  }
  if (type === "EBOOK") {
    inputs = content.filter((c) => [1, 2, 3, 4, 5, 6].includes(c.id));
  }
  if (type === "SPONSOR") {
    inputs = content.filter((c) => [1, 2, 3, 4, 5, 6, 7, 8].includes(c.id));
  }
  if (type === "MEDIA") {
    inputs = content.filter((c) => [1, 2, 3, 4, 5].includes(c.id));
  }
  if (redirect) {
    return <Redirect to={`thank-you/${type}`} />;
  }
  return (
    <div
      style={{
        paddingTop: "1rem",
        backgroundColor: "#7a3073",
        overflow: "hidden",
      }}
    >
      <Container>
        <h3 className="text-white text-center">{details.edition}</h3>
        <Row className="">
          <Col className="mx-auto mb-3 py-5" lg={8}>
            <Container className="my-auto text-white text-uppercase1 text-400-cx">
              <h1 className={`text-center ${editionLink ? 'd-none': ''}`}>Registration is closed.</h1>
              <Form onSubmit={onSubmitForm} className={editionLink ? 'register-form': 'd-none'}>
                <Row className="justify-content-center">
                  {inputs.map((i) => (
                    <Col lg={6} className="my-2" key={i.name}>
                      <label>{i.placeholder}*</label>
                      <Input
                        placeholder={i.placeholder}
                        type={i.type}
                        name={i.name}
                        onChange={onChangeDetails}
                        required
                      />
                    </Col>
                  ))}
                  {type !== "REGISTER1" && (
                    <Col lg={6} className="my-2">
                      <label>Industry *</label>
                      <Input
                        type="select"
                        name="industry"
                        id="industry"
                        onChange={onChangeDetails}
                        required
                      >
                        <option value="">SELECT</option>
                        {industries.map((industry) => (
                          <option key={industry}>{industry}</option>
                        ))}
                      </Input>
                    </Col>
                  )}
                  {type === "REGISTER" && (
                    <Col lg={6} className="my-2">
                      <label>Edition *</label>
                      <Input
                        type="select"
                        name="edition"
                        id="edition"
                        value={details.edition}
                        onChange={onChangeDetails}
                        required
                      >
                        <option value="">SELECT</option>
                        {edition.map((s) => (
                          <option key={s}>{s}</option>
                        ))}
                      </Input>
                    </Col>
                  )}
                  <Col lg={12} className="text-left pt-2">
                    <p style={{ textTransform: "none" }}>
                      <input
                        type="checkbox"
                        name='marketing'
                        onChange={(e) => {
                          if (e.target.checked) {
                            setDetails({ ...details, marketing: 'Yes' });
                          } else {
                            setDetails({ ...details, marketing: 'No' });
                          }
                        }}
                      />
                      &nbsp;
                      I would like to receive marketing communications on products, services or events.
                    </p>
                  </Col>

                  <Col lg={12} className="text-left pt-2">
                    <p style={{ textTransform: "none" }}>
                      <input
                        type="checkbox"
                        name='consent'
                        required
                        onChange={(e) => {
                          if (e.target.checked) {
                            setDetails({ ...details, consent: 'Yes' });
                          } else {
                            setDetails({ ...details, consent: 'No' });
                          }
                        }}
                      />
                      &nbsp;
                      I provide consent to Galadari Printing and Publishing to process my personal data in line with the{" "}
                      <a href="https://www.khaleejtimes.com/privacy-notice" target="_BLANK" rel="noopener noreferrer" style={{ color: 'rgb(87, 200, 232)' }}>Privacy Notice</a>
                    </p>
                  </Col>
                </Row>

                {loading ? (
                  <span>
                    <Spinner className="mt-3" color="warning" size="sm" />
                  </span>
                ) : (
                  <Button
                    block
                    className="btn text-white text-700 mt-3 border-0"
                    color=""
                    style={{ backgroundColor: "#f04c21" }}
                    size="lg"
                    type="submit"
                    disabled={loading || details.consent !== 'Yes'}
                  >
                    Submit
                  </Button>
                )}
              </Form>
            </Container>
            <CreateAlert success={success} error={error} />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

function CreateAlert({ success, error }) {
  const color = success ? "success" : "danger";
  const message = success ? success : error;
  if (!success && !error) {
    return "";
  } else {
    return (
      <Alert
        color={color}
        isOpen={true}
        fade={true}
        className="text-center"
        style={{ marginTop: "1rem" }}
      >
        {message}
      </Alert>
    );
  }
}

export default Register;

const content = [
  { id: 1, name: "first_name", type: "text", placeholder: "First Name" },
  { id: 2, name: "last_name", type: "text", placeholder: "Last Name" },
  { id: 3, name: "email", type: "text", placeholder: "Business Email" },
  { id: 4, name: "phone", type: "text", placeholder: "Mobile" },
  { id: 5, name: "company", type: "text", placeholder: "Company" },
  { id: 6, name: "job", type: "text", placeholder: "Job Title" },
  { id: 7, name: "country", type: "text", placeholder: "Country" },
  { id: 8, name: "city", type: "text", placeholder: "City" },
];

const industries = [
  "Aerospace",
  "Agriculture",
  "Automotive",
  "Banking/Finance",
  "Construction",
  "CX & UX",
  "Emergency Services",
  "Energy & Utilities",
  "Environment, Mining & Chemicals",
  "Government",
  "Healthcare",
  "ISP",
  "Investors/VC",
  "Manufacturing",
  "Oil & Gas",
  "Public Safety",
  "Publishers",
  "Retail/e-Commerce",
  "Sales & Marketing",
  "Technology",
  "Other",
];

const edition = [
  "CX EVOLVE 2025 - JAN/KSA Edition",
  "CX EVOLVE 2025 - AUG/UAE Edition",
];
